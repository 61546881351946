[data-theme='ergo-one'] .dropdown {
    width: 100%;
}
[data-theme='ergo-one'] .dropdown__label {
    display: inline-block;
    margin-bottom: 16px;
}
[data-theme='ergo-one'] .dropdown__input.ee_input-wrapper {
    margin: 0;
}
[data-theme='ergo-one'] .dropdown__input.ee_input-wrapper .ee_input {
    padding: 0;
}
[data-theme='ergo-one'] .dropdown__select .ee_select__control {
    padding-right: 32px;
}
[data-theme='ergo-one'] .dropdown .ee_select__control::after,
[data-theme='ergo-one'] .dropdown .ee_select__control::before {
    display: inline-block;
    content: '';
}
[data-theme='ergo-one'] .dropdown__errors {
    color: #e80c26;
    font-size: 12px;
    margin-top: 12px;
    list-style: none;
    padding-left: 0;
}
[data-theme='ergo-one'] .dropdown.dropdown--inline {
    display: flex;
    flex-direction: column;
    padding: 16px 0;
}
@media (min-width: 768px) {
    [data-theme='ergo-one'] .dropdown.dropdown--inline {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 16px;
   }
}
[data-theme='ergo-one'] .dropdown.dropdown--inline .dropdown__labelWrapper,
[data-theme='ergo-one'] .dropdown.dropdown--inline .dropdown__selectWrapper {
    display: flex;
}
[data-theme='ergo-one'] .dropdown.dropdown--inline .dropdown__labelWrapper {
    align-items: center;
    flex-basis: calc(100% / 3);
    min-width: 210px;
}
[data-theme='ergo-one'] .dropdown.dropdown--inline .dropdown__label {
    height: auto;
    margin-bottom: 0;
}
[data-theme='ergo-one'] .dropdown.dropdown--inline .dropdown__selectWrapper {
    flex: 1;
    flex-direction: column;
}
[data-theme='ergo-one'] .dropdown.dropdown--inline .dropdown__errorsWrapper {
    flex-basis: 100%;
    min-width: 0;
}
