.introduction_makler-number {
  color: #8e0038;
  font-size: 2rem;
  margin-left: 5px;
}

.introduction_center {
  text-align: center;
  margin-bottom: 4em;
}
