.selectable-card , .disabled-selectable-card {
    border-radius: 8px;
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, .2), 0 6px 10px 0 rgba(0, 0, 0, .14), 0 1px 18px 0 rgba(0, 0, 0, .12);
    margin-bottom: 1em;
    border: 1px solid #F2F2F2;
}

.selectable-card:hover {
    border-color: #8e0038;
    cursor: pointer;
}

.selectable-card:hover .selectable-card-left-column{
    background-color: #E4D6E4;
}

.selectable-card:hover .selectable-card-right-column button{
    background-color: #E4D6E4;
    text-decoration: underline;
}

.selectable-card-left-column {
    text-align: center;
    flex-direction: column;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    border: 0 solid #EFE7ED;
    display: flex;
    justify-content: center;
}

.selectable-card-left-column-header{
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 1em;
}

.selectable-card-right-column-header{
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 1em;
    float:left;
}

.selectable-card .selectable-card-left-column{
    background-color: #EFE7ED;
}

.disabled-selectable-card .selectable-card-left-column{
    background-color: #F2F2F2;
    color: #AEAEAE;

}

.disabled-selectable-card svg{
    fill: #AEAEAE !important;
    stroke: #AEAEAE !important;
}

.selectable-card-right-column {
    font-size: 14px;
    padding: 1em 1em 0 1em;
}

.selectable-card-right-column .selectable-card-right-column-select-line{
    height: 38px;
    font-size: 14px;
    text-align: right;
    float: right;
    justify-content: center;
    flex-direction: column;
    display: flex;
}

.selectable-card-right-column .selectable-card-right-column-select-line button {
    font-weight: bold !important;
}

.selectable-card-right-column .selectable-card-right-column-select-line .selectable-card-right-column-message {
    font-weight: bold;
    color: #333333;
    letter-spacing: 0.25px;
}

