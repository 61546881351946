@keyframes happy-sliding {
    from { opacity: 0%; height: 0em; }
    to { opacity: 100%; height: 5em; }
}

.fancy-text-popup {
    animation-name: happy-sliding;
    animation-duration: 1s;
    height: 5em;
}

@media (max-width: 270px) {
    @keyframes happy-sliding {
        from { opacity: 0%; height: 0em; }
        to { opacity: 100%; height: 8em; }
    }
    .fancy-text-popup {
        animation-name: happy-sliding;
        animation-duration: 1s;
        height: 8em;
    }
}
