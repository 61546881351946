.disclaimer-info-box .disclaimer-info-box-circle {
    width: 80px;
    height: 60px;
    padding-top: 20px;
    border-radius: 40px;
    background-color: #8e0038;
    color: #FFFFFF;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    font-weight: bold;
    transform: rotate(-15deg);
    margin-right: auto;
    margin-left: auto;
    box-sizing: initial;
}
