.error-modal__business-id {
    text-align: end;
    color: #767676;
}

.error-modal__message {
    line-height: 1.5;
}

.error-modal__primary-button {
    margin-top: 1.5em;
}

.error-modal__secondary-button {
    float: right;
}

.error-modal__anchor {
    text-decoration: none;
    color: inherit;
}
