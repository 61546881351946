.summary-container {
  text-align: center;
}

.container-title {
  margin: 0 0 3em 0;
}

.card {
  text-align: left;
}

.card-section {
  border-top-width: 0;
  border-bottom-width: 0;
  line-height: 20px;
}

.button {
  font-weight: bold;
}
