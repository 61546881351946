.footer__banner_isMakler {
    background: #8e0038;
    width: 100vw;
    position: relative;
    left: calc(-50vw + 50%);
  }
  
  .footer__banner-links__link {
    display: inline-block;
    text-decoration: none;
    cursor: pointer;
    color: #ffffff;
    padding-right: 10px;
  }
  
  .footer__banner-links {
    list-style-type: none;
    display: flex;
    flex-shrink: 1;
    padding: 0;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: flex-start;
    color: white;
  }
  
  @media (max-width: 768px) {
     .footer__banner-links {
      max-width: 550px;
      align-items: flex-end;
      justify-content: flex-end;
      margin-right: -10px;
    }
  }
  
   .footer__banner-links__item {
    line-height: 1;
    border-right: 1px solid #ffffff;
    margin: 0 10px 6px 0;
  }
  .footer__banner-links__item:last-child {
    border-right: none;
  }
  
  @media (min-width: 480px) {
     .footer__banner-links__item:last-child > .footer__banner-links__link {
      padding-right: 0;
    }
  }
