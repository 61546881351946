.errorDiv {
  width: 100%;
}

.error_message {
  margin: 20px;
  font-size: 14px;
  color: #8e0038;
  width: 800px;
  border-radius: 8px;
  border: 1px solid #8e0038;
  margin: auto;
  text-align: left;
}

.error_message svg {
  margin-right: 10px;
}
