.vklmDiv {
  width: '100%'
}

.vklmDiv .ee_form-row__descriptor.ee_form-row__descriptor--wide {
  width: fit-content;
}

.vklmDiv .ee_input {
  width: 100%;
}

.vklm_custom_label {
  transform: translate(0, -8px);
}

.vklm_custom_label .subtext {
  font-size: 12px;
  text-align: right;
}
