#leistungsbausteineModal > .ee_modal__main {
  /* max width noetig um EE-Styles sicher zu ueberschreiben. */
  max-width: 60em;
}
.feepage-variant {
  display: flex;
  justify-content: center;
  overflow: hidden;
}
.feepage-content {
  flex-basis: 550px;
  padding-bottom: 15px;
}
.feepage-disableed {
  color: lightgray;
}
.feepage-disableed table.feepage-checklist tr td:nth-child(1) {
  opacity: 50%;
}
.feepage-paymentmethod {
  padding: 0 3.5em 0 3.5em;
}
.feepage-notselectable {
  font-size: 1em;
  color: #333 !important;
  margin-top: 0.1em;
  margin-bottom: 1em;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
}
.feepage-tip {
  margin-top: 5px;
  color: #000;
}
.feepage-price {
  position: relative;
  height: 61px;
}
table.feepage-checklist {
  margin-bottom: 20px;
  width: 100%;
  border-spacing: 0px;
}
table.feepage-checklist tr {
  vertical-align: middle;
  padding: 5px 10px 4px 20px;
  text-align: left;
}
table.feepage-checklist tr:last-child td {
  border-bottom: none;
}
table.feepage-checklist tr td {
  vertical-align: middle;
}
table.feepage-checklist tr td:nth-child(1) {
  width: 24px;
  padding-left: 15px;
  padding-top: 5px;
}
table.feepage-checklist tr td:nth-child(2) {
  padding: 10px;
}
table.feepage-checklist tr td:nth-child(3) {
  width: 26px;
  height: 42px;
  padding-right: 5px;
}

.FeePage--options {
  max-width: 550px;
  padding: 20px;
  margin: 0 auto;
}

.feepage-content.feepage-disableed span[data-part="value"],
.feepage-content.feepage-disableed span[data-part="currency"] {
  color: lightgray;
}

@media (min-width: 480px) {
  table.feepage-checklist tr td {
    border-bottom: 1px solid #d9d9d9;
  }
}
