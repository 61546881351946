.ee_button {
  box-shadow: none !important;
}

.ee_form-footer__content {
  justify-content: center;
}

.grid-cell-vertical-centered {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.stg-custom-button {
  width: 300px;
  line-height: 44px !important;
}

.stg-custom-button[data-disabled="false"]:hover {
  background: #BF1528 !important;
  border-color: #BF1528 !important;
  color: #FFFFFF !important;
}

.stg-custom-button-button[data-disabled="true"],
.stg-custom-button[data-disabled="true"]:hover {
  color: #C8C8C8 !important;
  border-color: #C8C8C8 !important;
  background-color: #FFFFFF !important;
  cursor: default !important;
}

.stg-feedback-page__hero {
  background-color: #e3f0e6;
  text-align: center;
  width: 100vw;
  position: relative;
  left: calc(-50vw + 50%);
  padding-top: 32px;
  padding-bottom: 32px;
  margin-top: -40px;
}

.stg-feedback-page__hero-title {
  font-family: "Fedra Serif", "FS Me", Arial, Helvetica, sans-serif;
  font-weight: bold;
  letter-spacing: 0;
  text-align: center;
  color: #8e0038;
  hyphens: none;
  font-size: 24px;
  line-height: 32px;
  padding: 1rem 4rem;
}

.stg-feedback-page__hero-subtitle {
  font-size: 14px;
  line-height: 20px;
  padding: 20px;
  padding: 1rem 4rem;
  margin: auto;
}

.stg-feedback-page__body {
  display: grid;
  justify-content: center;
  margin-top: 32px;
  margin-left: 24px;
  margin-right: 24px;
  grid-template-columns: none;
  row-gap: 32px;
}

.stg-feedback-page__paragraph1 {
  text-align: center;
  line-height: 20px;
  font-size: 14px;
}

.eg_forceDownload .ee_button__wrapper,
.stepButton .ee_button__wrapper {
  padding: 0 13px 0 0px !important;
  width: auto !important;
}
