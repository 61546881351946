.insurancebegin-date-selection{
    text-align: center;
    margin: 0 auto;
    margin-bottom: 1.2rem;
    width: 20rem;
}
@media (min-width: 480px) {
    .insurancebegin-date-selection{
        width: 25rem;
        margin-bottom: 1.5rem;
    }
}

.insurancebegin-restriction-spacer{
    font-size: 0.8rem;
    height: 1rem;
}

.insurancebegin-restriction-message{
    font-size: 0.8rem;
    padding: 1.5em 0;
    display: flex;
    margin: 0 40px;
    flex-direction: column;
    justify-content: center;
}