.radio-button{
    min-width: 15rem;
    cursor: pointer;
    font-weight: 500;
    font-size: 18px;
    line-height: 10px;
    border-radius: 8px;
    background-color: transparent;
    border: 2px solid #aeaeae;
    width: 100%;
}

.radio-button-false:hover {
    border: 2px solid #fff;
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.20),
            0 6px 10px 0 rgba(0, 0, 0, 0.14),
            0 1px 18px 0 rgba(0, 0, 0, 0.12);
}

 .radio-button-true{
    background-color: rgba(142, 0, 56, .08);
    border: 2px solid #8e0038;
}
.radio-button-inline {
    display: flex;
    padding: 0 .5rem;
}