#basket {
    padding: 24px;
    margin-bottom: 32px;
}

.removeBorderBottom {
    border-bottom: 0;
}

.additionalOption .additionalOption-label,
.additionalOption .additionalOption-action {
    border-bottom: 1px solid lightgrey;
}

.additionalOption:last-of-type .additionalOption-label,
.additionalOption:last-of-type .additionalOption-action {
    border-bottom: 0;
}

.additionalOption-label {
    padding: 10px 0;
    border-right: 0;
}

.additionalOption-action {
    border-left: 0;
}

#basket .insurance-calculation {
    box-shadow: none;
    margin-top: 0;
    margin-bottom: 0;
}

.basket-headline-variant {
    text-align: center;
}


.basket-headline-price {
    padding-top: 0;
    text-align: center;
}

@media (min-width: 768px) {
    .basket-headline-variant {
        text-align: left;
    }

    .basket-headline-price {
        text-align: right;
        padding-top: 0.5em;
    }
}
